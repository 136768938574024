import * as types from '../actionTypes'

export const updateCartModal = (isOpen) => async (dispatch) => {
  await dispatch({
    type: types.UPDATE_CART_MODAL_SUCCESS,
    payload: isOpen,
  })
}

export const updateLoginModal = (isOpen) => async (dispatch) => {
  await dispatch({
    type: types.UPDATE_LOGIN_MODAL_SUCCESS,
    payload: isOpen,
  })
}

export const updateCreateModal = (isOpen) => async (dispatch) => {
  await dispatch({
    type: types.UPDATE_SIGNUP_MODAL_SUCCESS,
    payload: isOpen,
  })
}

export const updateResetPasswordModal = (isOpen) => async (dispatch) => {
  await dispatch({
    type: types.UPDATE_RESET_MODAL_SUCCESS,
    payload: isOpen,
  })
}

export const redirectToMainPage = (isOpen) => async (dispatch) => {
  await dispatch({
    type: types.REDIRECT_TO_MAIN_PAGE_SUCCESS,
    payload: isOpen,
  })
}
